// import 'bootstrap/dist/css/bootstrap.min.css';
import "./src/scss/bootstrap.scss";
import 'jquery/dist/jquery.min.js'
import "@popperjs/core/dist/umd/popper.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import React from 'react';
import { StateProvider } from './src/providers/stateProvider';

export const wrapRootElement = ({ element }) => {
    return <StateProvider>{element}</StateProvider>;
};

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => scrollToAnchor(location, getSavedScrollPosition, 64)

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */
function scrollToAnchor(location, getSavedScrollPosition, mainNavHeight = 0) {
    // Check for location so build does not fail
    if (location && location.hash) {
        const item = document.querySelector(`${location.hash}`).offsetTop;
        const main = document.querySelector('.layout').clientHeight;

        window.scrollTo({
            top: main - item + mainNavHeight,
            behavior: "smooth",
        })
    } else {
        const currentPosition = getSavedScrollPosition(location)
        window.scrollTo(...(currentPosition || [0, 0]))
    }
    return false
}