import React, { useState } from 'react';
const defaultState = {
  display: false,
  changeVisibility: () => {},
  open: false,
  changeOpenMenu: () => {},
};
type UseDisplay = {
  display: boolean;
  changeVisibility: any;
  open: boolean;
  changeOpenMenu: any;
};

export const StateContext = React.createContext<UseDisplay>(defaultState);

export const StateProvider = ({ children }) => {
  const [display, setDisplay] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <StateContext.Provider
      value={{
        display,
        changeVisibility: () => setDisplay(!display),
        open,
        changeOpenMenu: () => setOpen(!open),
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
